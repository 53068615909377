<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="fullheight" style="width: 100%; margin: auto;">
        <CCol cols="12" md="12" style="width: 100%; margin-top: 80px !important;">
          <div class="login__container" style="margin: auto; background-color: #F6F6F6 !important;">
            <div class="login__inner">
              <div class="login__image">
                <img src="main_logo.jpg" style="width: 300px; margin: 0 0px;" alt="Logo Oscuro">
              </div>
              <div class="login__header pt-5">
                  <h4>Inicia Sesión</h4>
              </div>
              <div class="login__subscribe d-flex justify-space-between align-center pb-8">
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="email"
                      label="Correo Electrónico"  
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="password"
                      label="Contraseña"
                      type="password"
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <br />
                    <CButton type="button" color="primary" style="width: 100%" @click="login">INICIAR SESIÓN</CButton>
                  </CCol>
                </CRow>
              </div>
              <div class="login__actions d-flex justify-space-between py-2">
                  <div class="login__remind" style="font-size: 12px;">
                  </div>
                  <div class="login__recovery-password" style="width: 100%">
                      <!-- <a href="/#/forget" class="font-weight-regular px-1" style="font-size: 12px; float: right !important;">¿Olvidaste tu contraseña?</a> -->
                  </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
    <loading :active="visible" :can-cancel="true" loader="bars"></loading>
    <CToaster placement="top-end">
      <template>
        <CToast v-for="(toast, key) in toasts" :color="toast.color" v-bind="toast" :key="'toast' + key" :show="true">
          {{toast.content}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>
<script>
import router from '../../../router/index'
import login from '../../../services/login';
import ws from '../../../services/general';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from '../../../store'
import axios from 'axios'

export default {
  name: 'Login',
  data: function () {
      return {
          email: '',
          password: '',
          image: ''
      }
  },
  components: {
    Loading
  },
  mounted: async function() {
    let response = await ws.getParameters(); 

    if(response.type == "success"){
      let parameters = response.data;

      let param1 = this.$findElementByName(parameters, "LOGIN_IMAGE");
      this.image = 'login.jpg';
    }
  },
  methods: {
    login: async function(){
      this.loading();
      let params = {
        email: this.email,
        password: this.password,
        device_name: navigator.userAgent
      };

      let response = await login.login(params);

      if(response.type == "success"){
        let data = response.data;

        localStorage.setItem("domain", data.account.domain);
        localStorage.setItem("token", data.api_token);

        localStorage.setItem("role", data.role ? data.role : data.roles[0]);

        axios.defaults.headers.common['domain'] = data.account.domain;
        axios.defaults.headers.common['api-token'] = data.api_token;
        
        this.loaded();
        if(response.data.is_admin){
          window.location.href = "/#/accounts"; return;
        }else{
          if(data.role ? data.role : data.roles[0].slug == "administrator"){
            window.location.href = "/#/home"; return;
          }
          else{
            if(data.role ? data.role : data.roles[0].slug == "supplier"){
              window.location.href = "/#/welcome"; return;
            }
            else{
              if(data.role ? data.role : data.roles[0].slug == "manager"){
                window.location.href = "/#/welcome"; return;
              }
              else{
                if(data.role ? data.role : data.roles[0].slug == "kam"){
                  window.location.href = "/#/home"; return;
                }
                else{
                  window.location.href = "/#/404"; return;
                } 
              } 
            } 
          }
        }
      }
      else{
        this.loaded();
        this.showToast("error",response.message);
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {      
    visible() {
      return store.state.isLoading;
    },
    toasts () {
      return store.state.toasts;
    },
  },  
}
</script>
